import axios from 'axios';
import React, { ReactNode, createContext, useContext, useRef, useEffect } from 'react';

interface FileCacheInterface {
  fetchFile : (url : string) => Promise<string>;
}

interface Props {
  children?: ReactNode
}

const FileCacheContext = createContext<FileCacheInterface | null>(null);

const FileCache = ({ children } : Props) => {
  const downloadedFilesRef = useRef(new Map<string, string>());
  const pendingFilesRef = useRef(new Map<string, Promise<string>>());
 
  //Release all object urls on dismount
  useEffect(() => {
    const downloadedFiles = downloadedFilesRef.current;
    const downloadingFiles = pendingFilesRef.current;

    return () => {
      console.log('releasing cache', downloadedFiles);
      for(const objectUrl of Object.values(downloadedFiles)) {
        URL.revokeObjectURL(objectUrl);
      }

      downloadedFiles.clear();
      downloadingFiles.clear();    
    }
  }, []);

  const fetchFile = async (url : string) => {
    console.log('fetchFile', url);
    
    //Is already an object url
    if(url.startsWith('blob:')) {
      console.log('is blob');

      return url;
    }

    //Already downloaded file
    if(downloadedFilesRef.current.has(url)) {
      console.log('exists');
      return downloadedFilesRef.current.get(url) ?? url;
    }

    //Wait for existing download
    if(pendingFilesRef.current.has(url)) {
      console.log('pending');
      
      const promise = pendingFilesRef.current.get(url);

      if(promise) {
        console.log('existing promise');

        const ret = await promise;
        console.log('existing promise done', ret);
        return ret;
      }
      else return url;
    }

    console.log('fetching', url);
    
    //Start download
    try {
      const fetchData = async () => {
        const response = await axios.get(url, {responseType: 'blob'});
        pendingFilesRef.current.delete(url);
        
        const objectUrl = URL.createObjectURL(response.data);
        downloadedFilesRef.current.set(url, objectUrl);

        console.log('fetched', url);
        console.log('returning', objectUrl);

        return objectUrl;
      };

      const promise = fetchData();
      pendingFilesRef.current.set(url, promise);

      return await promise;
    } catch (error) {
      console.log(error);
      
      pendingFilesRef.current.delete(url);
      return url; 
    }
  }

  return (
    <FileCacheContext.Provider value={{fetchFile}}>
      {children}
    </FileCacheContext.Provider>
  );
}

export default FileCache;

export const useCacheContext = () => {
  return useContext(FileCacheContext) as FileCacheInterface;
}
