import { Link } from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

import UserAvatar from '../UserAvatar';
import NavMenu from "./NavMenu";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateFrom from "../Hooks/useNavigateFrom";
import { useEnvironmentContext } from "../Contexts/EnvironmentProvider";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import User from "../interfaces/User";
import { useNavigateAltSite } from "../Hooks/useNavigateAltSite";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DataArrayOutlinedIcon from '@mui/icons-material/DataArrayOutlined';
import LogoIcon from "../Icon/LogoIcon";
import GradientLine from "./GradientLine";

interface Props {
  selectedTab: string | null;

  onHome: () => void;
  onFavorite: () => void;
}
const Header = ({ selectedTab, onHome, onFavorite }: Props) => {
  const navigate = useNavigateFrom();
  const navigateAlt = useNavigateAltSite();

  const { user, signOut } = useAuthContext();
//  const { siteVariant } = useEnvironmentContext();

  const [school, setSchool] = useState<string | undefined>();

  const theme = useTheme();

  const buttonStyle: React.CSSProperties = { color: theme.palette.text.primary, textTransform: 'none', fontSize: '10pt', whiteSpace: 'nowrap', minWidth: 'auto', alignSelf: 'flex-start' };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSignOut = async () => {
    await signOut();
    navigateAlt('/');
  }
  useEffect(() => {
    let profileUrl = `/api/users/${user?.id}`;

    axios.get<User>(profileUrl)
      .then(res => {
        const userDetail = res.data;

        if (userDetail.classes && userDetail.classes.length > 0) {
          setSchool(userDetail.classes[0].school.name);
        }
      });
  }, []);

  const handleAvatarClick = () => {
    if (!user) return;

    if (user.handle)
      navigate(`/members/${user.handle}`);

    else navigate(`/profile/${user.id}`);
  }

  const getLogoSrc = () => {
    //    if (siteVariant === 'school') {
    //      return theme.palette.mode === 'dark' ? "/logo-school-white.png" : "/logo-school.png";
    //    } else {
    return theme.palette.mode === 'dark' ? "/logo-white.png" : "/logo.png";
    //    }
  }

  const getNavMenuItems = () => {
    let ret = [];

    if (user) {
      ret.push({ path: '/history', label: 'History' });
    }
    //    ret.push({ path: '/', label: 'Channels' });

    return ret;
  }

  if (!isMobile) {
    return (
      <Box sx={{
        flexShrink: '0',
        height: '60px',
        backgroundColor: theme.palette.background.default,
      }}>
  
        <Stack
          sx={{ py: '10px', height: '40px' }}
          direction='row'
          alignItems='center'
        >
          <NavMenu iconElement={<MenuIcon />} mobile={isMobile} items={getNavMenuItems()} />
  
          <img style={{ height: '30px', marginLeft: '15px' }} onClick={() => navigate('/')} src='/logo-podcast-white.png' />
  
          <div style={{ flex: '1' }} />
  
          <IconButton sx={{ mr: '10px', my: '-8px', alignItems: 'center' }} onClick={onHome}>
            <HomeOutlinedIcon sx={{ fontSize: "30px", color: selectedTab === 'home' ? '#FF017E' : 'white' }} />
          </IconButton>
  
          <IconButton sx={{ mr: '10px', alignItems: 'center' }} onClick={onFavorite}>
            <FavoriteBorderOutlinedIcon sx={{ fontSize: "30px", color: selectedTab === 'favorite' ? '#FF017E' : 'white' }} />
          </IconButton>
  
          {user &&
            <IconButton onClick={handleAvatarClick} sx={{ mr: '5px' }}>
              <UserAvatar user={user} sx={{
                width: '40px',
                height: '40px',
              }} />
            </IconButton>
          }
        </Stack>
        <GradientLine />
      </Box>
    );
  }

  return (
    <Box sx={{
      flexShrink: '0',
      height: { 'xs': '60px', 'md': '90px' },
      backgroundColor: theme.palette.background.default,
    }}>

      <Stack
        sx={{ py: '10px', height: '40px' }}
        direction='row'
        alignItems='center'
      >
        <NavMenu iconElement={<MenuIcon />} mobile={isMobile} items={getNavMenuItems()} />

        <img style={{ height: '30px', marginLeft: '15px' }} onClick={() => navigate('/')} src='/logo-podcast-white.png' />

        <div style={{ flex: '1' }} />

        <IconButton sx={{ mr: '10px', my: '-8px', alignItems: 'center' }} onClick={onHome}>
          <HomeOutlinedIcon sx={{ fontSize: "30px", color: selectedTab === 'home' ? '#FF017E' : 'white' }} />
        </IconButton>

        <IconButton sx={{ mr: '10px', alignItems: 'center' }} onClick={onFavorite}>
          <FavoriteBorderOutlinedIcon sx={{ fontSize: "30px", color: selectedTab === 'favorite' ? '#FF017E' : 'white' }} />
        </IconButton>

        {user &&
          <IconButton onClick={handleAvatarClick} sx={{ mr: '5px' }}>
            <UserAvatar user={user} sx={{
              width: '40px',
              height: '40px',
            }} />
          </IconButton>
        }
      </Stack>

      <Divider sx={{ background: '#fff' }} />
    </Box>
  );
}

export default Header;
