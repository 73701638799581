import axios from "axios";

const getRandomString = (length : number) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const getSessionId = () => {
  let sessionId: string | null = null;

  const now = new Date();

  sessionId = sessionStorage.getItem('sessionId');

  if (sessionId) {
    let lastActivityString = sessionStorage.getItem('lastActivity');

    if (lastActivityString) {
      const lastActivity = new Date(parseInt(lastActivityString, 10));

      if(now.getTime() - lastActivity.getTime() > 30 * 60 * 1000) {
        sessionId = null;
      }
    } else {
      sessionId = null;
    }
  }

  if (!sessionId) {
    sessionId = getRandomString(10);
    sessionStorage.setItem('sessionId', sessionId);
  }

  sessionStorage.setItem('lastActivity', '' + now.getTime());

  return sessionId;
}

const useActivityLogger = () => {
  const location = window.location;

  return async (action: string, context: any, userId: string | undefined = undefined) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const localTime = new Date(new Date().getTime() - timezoneOffset * 60000);;
    const session = getSessionId();
    const path = location.pathname;

    await axios.post('/api/activities/', { userId, localTime, session, path, action, context });
  }
}

export default useActivityLogger;