import React, { useState, useRef, useEffect, ForwardedRef } from 'react';

import Avatar from '@mui/material/Avatar';

import User from './interfaces/User';

import { useCacheContext } from './Contexts/FileCache';
import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material';

interface Props {
  user?: User;
  sx?: SxProps<Theme>;
  onClick?: (imageUrl: string | null) => void;
  [x: string]: any;
}

const UserAvatar = React.forwardRef((props: Props, ref : ForwardedRef<HTMLDivElement> | null) => {
  const { user, onClick, sx, ...propsLeft } = props;

  const { fetchFile } = useCacheContext();

  const [source, setSource] = useState('');

  const hasImage = useRef(false);

  const photoId = user?.photo;

  useEffect(() => {
    setSource('/null');

    if (photoId) {
      fetchFile(`/api/users/photo/${photoId}`).then(ret => setSource(ret));
    }
  }, [photoId]);


  const handleClick = () => {
    onClick?.(hasImage.current ? source : null);
  }
  /*
    useEffect(()=> {
      const controller = new AbortController();
  
      axios.get(`/api/users/${id}/photo`, { signal: controller.signal, responseType: 'blob'})
      .then(res => {
        setSource(URL.createObjectURL(res.data));
      })
      .catch(err => {
        setSource('/null');
      });
  
      return () => {
        controller.abort();
      }    
    }, [id]);
    */

  const name = user?.name ?? 'Guest';

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const newSx = { ...sx, backgroundColor: stringToColor(name), cursor: 'pointer' };
  const childProps = { sx: newSx, ...propsLeft };

  const handleLoaded = () => {
    hasImage.current = true;
  }

  return (
    <Avatar ref={ref} alt={name} src={source} onClick={handleClick} imgProps={{ onLoad: handleLoaded }} {...childProps} />
  );
});

export default UserAvatar;
