import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert, FormControl, IconButton, InputAdornment, OutlinedInput, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

interface Props {
  onAuthenticated: () => void,
  onNavigateLogin: () => void,
}

const ResetPassword = ({ onAuthenticated, onNavigateLogin }: Props) => {
  const [state, setState] = useState<'username' | 'code' | 'password' | 'done'>('username');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState<string>('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const isEnabled = true;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = () => {
    if (password.length < 6) {
      setError('Your password needs to contain at least 6 characters.');
      return false;
    }

    return true;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    switch (state) {
      case 'username':
        await handleGetCode();
        break;
      case 'code':
        await handleVerifyCode();
        break;

      case 'password':
        await handleResetPassword();
        break;
      default:
        onAuthenticated();
        break;
    }
  }

  const handleGetCode = () => {
    setLoading(true);

    axios.post('/api/users/password/code', { username: email })
      .then(res => {
        setState('code');
        setError(null);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setError(error.response.data);
        } else {
          setError('An error occurred.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleVerifyCode = async () => {
    setLoading(true);

    axios.post('/api/users/password/verify', { username: email, code: code })
      .then(res => {
        setState('password');
        setError(null);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setError(error.response.data);
        } else {
          setError('An error occurred.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleResetPassword = async () => {
    if (!validatePassword()) return;

    setLoading(true);

    axios.post('/api/users/password/reset', { username: email, code: code, password: password })
      .then(res => {
        setState('done');
        setError(null);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setError(error.response.data);
        } else {
          setError('An error occurred.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getCommand = () => {
    switch (state) {
      case 'username': return 'Reset Password';
      case 'code': return 'Next';
      case 'password': return 'Submit';
      default: return 'OK';
    }
  }

  const getContent = () => {
    if (state === 'username') {
      return (
        <>
          <Typography sx={{ mx: '20px', mt: '20px' }}>Email</Typography>
          <TextField
            sx={{ m: '20px', mt: '10px' }}
            variant='outlined'
            value={email}
            type='email'
            onChange={e => setEmail(e.target.value)}
          />
        </>
      );
    }

    if (state === 'code') {
      return (
        <>
          <Typography sx={{ mx: '20px', mt: '20px' }}>Enter the 6-digit code sent to your email.</Typography>
          <TextField
            sx={{ mx: '20px', my: '10px' }}
            variant='outlined'
            type='number'
            value={code}
            onChange={e => setCode(e.target.value)}
          />
          <Button
            sx={{ fontWeight: 'bold', color: theme.palette.text.primary, textTransform: 'none', marginLeft: '20px', marginRight: 'auto', marginTop: '0px', marginBottom: '10px', py: '0px' }}
            onClick={handleGetCode}
          >
            Resend code
          </Button>

          <Typography sx={{ mx: '20px', mt: '25px' }}>If you don't see the email in your inbox, check your spam folder.</Typography>
        </>
      )
    }

    if (state === 'password') {
      return (
        <>
          <Typography sx={{ mx: '20px', mt: '20px' }}>Enter your new password</Typography>

          <FormControl sx={{ m: '20px', mt: '10px' }} variant="outlined">
            <OutlinedInput
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </>
      );
    }

    return <Typography textAlign='center' sx={{ mx: '20px', mt: '40px' }}>Your password has been reset successfully.</Typography>;
  }

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}
      onSubmit={handleSubmit}
    >

      {error &&
        <Alert severity="error" sx={{ mt: '20px' }}>{error}</Alert>
      }

      {getContent()}

      <Button type='submit' sx={{
        m: '20px', p: '15px', backgroundColor: theme.palette.button.main, textTransform: 'none', fontSize: '20pt',
        borderRadius: '20px'
      }} variant="contained">{getCommand()}</Button>
    </Box>
  );
}

export default ResetPassword;
