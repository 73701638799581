import React from 'react';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import {
  useNavigate,
  useLocation,
} from "react-router-dom";

import { useAuthContext } from "./Contexts/AuthProvider";

import { useEnvironmentContext } from "./Contexts/EnvironmentProvider";

import useActivityLogger from './Hooks/useActivityLogger';
import MainSite from './Sites/MainSite';
import MetricsSite from './Sites/MetricsSite';
import PodcastSite from './Sites/PodcastSite';

export default function App() {
  const { token, refreshToken } = useAuthContext();

  const { siteVariant, setSiteVariant, pageVariant, setPageVariant } = useEnvironmentContext();

  const [bootingUp, setBootingUp] = useState(true);

  const requestInterceptorRef = useRef<number | null>(null);
  const responseInterceptorRef = useRef<number | null>(null);

  const tempTokenRef = useRef(null);

  const navigate = useNavigate();
  const postActivity = useActivityLogger();

  const navigateToRoot = () => {
    navigate('/', { replace: true });
  };

  //Get token when app loads.
  useEffect(() => {
    refreshToken()
      .then(() => {
        setBootingUp(false);
      });
  }, []);

  useEffect(() => {
    const subdomain = window.location.host.split('.')[0];

    //    const subdomain = 'dashboard';

    if (subdomain === 'dashboard' || subdomain === 'school') {
      setSiteVariant(subdomain);
    }

    if (subdomain.includes('school')) {// || window.location.host.includes('vactors.club')) {
      setSiteVariant('school');
    }

    if (subdomain.includes('podcast')) {
      setPageVariant('channel');
    } else if (subdomain === 'dashboard') {
      setPageVariant('dashboard');
    }
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (bootingUp) return;

    console.log('location changed');

    const locationNow = location.pathname;
    console.log('location now', locationNow);
    setTimeout(() => {
      const locationLater = window.location.pathname;
      console.log('location later', locationLater);

      if (locationNow === locationLater) {
        postActivity('visit', {});
      } else {
        console.log('redirect');
      }
    }, 1000);
  }, [location.pathname, bootingUp]);

  if (bootingUp) {
    return <div />;
  }

  if (requestInterceptorRef.current != null) {
    axios.interceptors.request.eject(requestInterceptorRef.current);
  }

  requestInterceptorRef.current = axios.interceptors.request.use(
    (config) => {
      if (tempTokenRef.current)
        console.log('tempTokenRef!');

      const newToken = tempTokenRef.current ? tempTokenRef.current : token;
      tempTokenRef.current = null;

      if (newToken && config.headers)
        config.headers['Authorization'] = `Bearer ${newToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  if (responseInterceptorRef.current != null) {
    axios.interceptors.response.eject(responseInterceptorRef.current);
  }

  responseInterceptorRef.current = axios.interceptors.response.use(
    (response) => {
      //  console.log('intercept response');
      return response;
    },
    async (error) => {
      console.log('intercept response error');
      const originalRequest = error.config;

      console.log(originalRequest);
      if (error.response.status === 401) {
        if (originalRequest._retry) {
          navigateToRoot();
          return Promise.reject(error);
        }

        originalRequest._retry = true;

        tempTokenRef.current = await refreshToken();

        return axios(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  if (siteVariant === 'dashboard' || window.location.href.includes('/metrics')) {
    return <MetricsSite />;
  }

  if (pageVariant === 'channel') {
    return <PodcastSite />;
  }
  return <MainSite />;
}

export const formatDate = (date: Date | string) => {
  return new Date(date).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
}
