import React, { ReactNode, createContext, useState, useContext, useEffect } from 'react';
import { useAuthContext } from './AuthProvider';

type SiteVariant = 'default' | 'dashboard' | 'school';

type PageVariant = 'main' | 'dashboard' | 'channel';

interface EnvironmentContextInterface {
  siteVariant: SiteVariant;
  setSiteVariant: React.Dispatch<React.SetStateAction<SiteVariant>>;

  pageVariant: PageVariant;
  setPageVariant: React.Dispatch<React.SetStateAction<PageVariant>>;

  lastSeenNotification: number;
  setLastSeenNotification: React.Dispatch<React.SetStateAction<number>>;
}

interface Props {
  children?: ReactNode
}

const EnvironmentContext = createContext<EnvironmentContextInterface | null>(null);

const EnvironmentProvider = ({ children }: Props) => {
  const { user } = useAuthContext();

  const [siteVariant, setSiteVariant] = useState<SiteVariant>('default');
  const [pageVariant, setPageVariant] = useState<PageVariant>('main');

  const [lastSeenNotification, setLastSeenNotification] = useState(0);

  useEffect(() => {
    setLastSeenNotification(-1);
  }, [user]);

  return (
    <EnvironmentContext.Provider value={{ siteVariant, setSiteVariant, pageVariant, setPageVariant, lastSeenNotification, setLastSeenNotification }}>
      {children}
    </EnvironmentContext.Provider>
  );
}

export default EnvironmentProvider;

export const useEnvironmentContext = () => {
  return useContext(EnvironmentContext) as EnvironmentContextInterface;
}
