import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals.js';
import AuthProvider from "./Contexts/AuthProvider";
import FriendProvider from './Contexts/FriendProvider';
import FileCache from './Contexts/FileCache';

import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import EnvironmentProvider from './Contexts/EnvironmentProvider';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './Misc/Azure/authConfig';
import { MsalProvider } from '@azure/msal-react';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

/*
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.

  alert(`all accounts = ${msalInstance.getAllAccounts().length}`);

  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
else {
  if(msalInstance.getActiveAccount()) {
    alert('has active account');
  }

  if(msalInstance.getAllAccounts().length === 0) {
    alert('no cached account');
  }
}
*/

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  const eventType = event.eventType;
  const payload: any = event.payload;

  if (eventType === EventType.LOGIN_START) {
//    alert('Login start');
    window.sessionStorage.setItem('pendingLogin', 'ms');
  }

  if (eventType === EventType.LOGIN_FAILURE) {
//    alert('Login fail');
    window.sessionStorage.removeItem('pendingLogin');
  }

  if (eventType === EventType.LOGIN_SUCCESS && payload.account) {
//    alert('logged in!');
    window.sessionStorage.removeItem('pendingLogin');

    const account = payload.account;
    msalInstance.setActiveAccount(account);
    window.location.replace('/auth/ms/login');
  }
});

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <AuthProvider>
      <EnvironmentProvider>
        <FriendProvider>
          <FileCache>
            <BrowserRouter>
              <ScrollToTop />
              <App />
            </BrowserRouter>
          </FileCache>
        </FriendProvider>
      </EnvironmentProvider>
    </AuthProvider>
  </MsalProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
