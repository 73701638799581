import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";

interface LocationState {
  from: string
}

const useNavigateRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (to : string, options? : NavigateOptions | undefined) => {
    const { from } = location.state as LocationState || { from: "/" };

    const newState = {from:from, ...options?.state};
    const newOptions : NavigateOptions = { replace:true, ...options, state: newState};

    console.log(`navigate through from ${from} via ${location.pathname} to ${to}`);
    
    navigate(to, newOptions);  
  }
}

export default useNavigateRedirect;