import axios from 'axios';
import React, { ReactNode, createContext, useContext, useRef } from 'react';

import User from '../interfaces/User';
import { useAuthContext } from './AuthProvider';

interface FriendContextInterface {
  getFriends: () => User[];
  isFriend: (user: User) => Boolean;
  fetchFriends: () => Promise<void>;
}

interface Props {
  children?: ReactNode
}

const FriendContext = createContext<FriendContextInterface | null>(null);

const FriendProvider = ({ children }: Props) => {
  const { user: currentUser } = useAuthContext();

  const friendsRef = useRef<User[]>([]);

  const getFriends = () => friendsRef.current;

  const isFriend = (user: User) => {
    //    console.log('isFriend', user, currentUser, friendsRef);

    if (user.id === currentUser?.id) return true;

    for (const friend of friendsRef.current) {
      if (user.id === friend.id) return true;
    }
    return false;
  }

  const fetchFriends = async () => {
    try {
      const res = await axios.get<{ user: User }[]>(`/api/friendships/`);
      friendsRef.current = res.data.map(x => x.user);
    } catch (error) {
      friendsRef.current = [];
    }
  }

  return (
    <FriendContext.Provider value={{ getFriends, isFriend, fetchFriends }}>
      {children}
    </FriendContext.Provider>
  );
}

export default FriendProvider;

export const useFriendContext = () => {
  return useContext(FriendContext) as FriendContextInterface;
}
