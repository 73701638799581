interface Props {
  sx?: React.CSSProperties
}

const GradientLine = ({ sx = {} }: Props) => {
  return (
    <div style={{
      backgroundImage: 'linear-gradient(to right, #04AEFF, #AD00FF 50%, #FF017E)',
      height: '5px',
      ...sx
    }} />
  );
}

export default GradientLine;