import React, { Suspense } from 'react';

import {
  useNavigate,
} from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import Box from '@mui/material/Box';

import Header from "./Header";
import { createTheme, ThemeProvider } from '@mui/material';

const LoginPanel = React.lazy(() => import("../Login/LoginPanel"));
const MetricsPage = React.lazy(() => import('../Misc/Metrics'));

export default function MetricsSite() {
  const { hasPrivilege } = useAuthContext();

  const navigate = useNavigate();

  const navigateToRoot = () => {
    navigate('/', { replace: true });
  };

  const theme = createTheme({
    palette: {
      button: {
        main: 'black'
      },
      modal: {
        main: '#fff'
      },
      theme: {
        main: "#BA58E9"
      }
    }
  });

  console.log('metrics theme', theme);

  return (
    <ThemeProvider theme={theme}>
      <div style={{
        position: 'absolute', top: '0px', left: '0px', right: '0px',
        minHeight: '100dvh',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box sx={{
          width: { 'xs': '100%', 'md': '100%' },
          position: 'relative',
          backgroundColor: 'white',
          display: 'flex'
        }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%'
            }}>
            <Header />

            <Box
              sx={{
                backgroundColor: 'black',
                height: '1px',
                width: '100%'
              }} />

            <Box
              sx={{
                width: '100%',
                flexGrow: '1'
              }}>
              <Suspense fallback={<div />}>
                {hasPrivilege('show_metrics') ?
                  <MetricsPage />
                  :
                  <LoginPanel initialState='login' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />
                }
              </Suspense>
            </Box>
          </Box>
        </Box>
      </div >
    </ThemeProvider>
  );
}
