import { useMsal } from "@azure/msal-react";
import { Button, Stack, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "./authConfig";

interface Props {
  sx? : SxProps<Theme>;
}

const SignInButton = ({ sx } : Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleSignIn = () => {
    navigate('/auth/ms/login?action=login');
  }

  return (
    <Button
    variant="contained"
    sx={{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      textTransform:'none',
      background:theme.palette.button.main,
      px:'12px', height:'41px', borderWidth:'1px', borderColor:'#8c8c8c',
      ...sx
    }}
    onClick={handleSignIn}
    >
      <img src="/ms-logo.svg" style={{marginRight:'12px'}} />
      <Typography fontSize='15px' color={theme.palette.background.default} fontWeight='600'>Sign in with Microsoft</Typography>
    </Button>
  );
}

export default SignInButton;