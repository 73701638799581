import { useLocation, useNavigate } from "react-router-dom";

interface LocationState {
  from: string
}

const useNavigateBack = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return () => {
    const { from } = location.state as LocationState || { from: "/" };
  
    console.log(`navigate back to ${from}`);
    navigate(from, { replace: true });  
  }
}

export default useNavigateBack;