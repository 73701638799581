import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";

const useNavigateFrom = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (to : string, options? : NavigateOptions | undefined) => {
    const newState = {from:location.pathname, ...options?.state};
    const newOptions : NavigateOptions = {...options, state: newState};

    console.log(`navigate from ${newOptions.state.from} to ${to}`);
    
    navigate(to, newOptions);  
  }
}

export default useNavigateFrom;