import axios from 'axios';
import React, { ReactNode, createContext, useState, useContext, useRef } from 'react';
import useActivityLogger from '../Hooks/useActivityLogger';
import Subscription from '../interfaces/Subscription';

import User from '../interfaces/User';

interface AuthContextInterface {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  refreshToken: () => Promise<any>;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  reloadSubscription: () => Promise<void>;
  fetchSubscription: (user: User) => Promise<Subscription[]>;
  updateCredential: (user: User | null, token: string | null) => Promise<any>;
  hasPrivilege: (privilege: string) => boolean;
  signOut: () => Promise<void>;
}

interface Props {
  children?: ReactNode
}

const AuthContext = createContext<AuthContextInterface | null>(null);

const AuthProvider = ({ children }: Props) => {
  const [token, setToken] = useState<string | null>(null);

  const [user, setUser] = useState<User | null>(null);

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const postActivity = useActivityLogger();

  const refreshToken = async () => {
    try {
      const ret = await axios.create().post('/api/users/refresh-token');
      console.log('new token', ret);
      await updateCredential(ret.data.user, ret.data.token);

      return ret.data.token;
    } catch (err) {
      await updateCredential(null, null);

      return null;
    }
  };

  const signOut = async () => {
    await axios.post('/api/users/signOut');
    postActivity('logout', {});
    updateCredential(null, null);
  }


  const updateCredential = async (user: User | null, token: string | null) => {
    setUser(user);
    setToken(token);

    if (user) {
      const newAxios = axios.create({
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const ret = await newAxios.get<any[]>('/api/subscriptions');

      const newSubscriptions: Subscription[] = ret.data.map(x => ({
        role: x.role,
        expiry: new Date(x.expiry)
      }));

      console.log('set subscriptions', subscriptions);
      setSubscriptions(newSubscriptions);
    } else {
      setSubscriptions([]);
    }
  }

  const reloadSubscription = async () => {
    if (!user) return;

    const ret = await fetchSubscription(user);
    setSubscriptions(ret);
  }

  const fetchSubscription = async (user: User) => {
    const ret = await axios.get<any[]>('/api/subscriptions',
      { params: { user: user.id } });

    const subscriptions: Subscription[] = ret.data.map(x => ({
      role: x.role,
      expiry: new Date(x.expiry)
    }));

    return subscriptions;
  }

  const hasPrivilege = (privilege: string) => {
    if (!user) return false;

    const now = new Date();
    for (const subscription of subscriptions) {
      if(subscription.expiry < now) {
        continue;
      }

      if (subscription.role.privileges.includes(privilege)) {
        return true;
      }
    }

    return user?.role?.privileges?.includes(privilege) ?? false;
  }

  return (
    <AuthContext.Provider value={{
      token, setToken,
      user, setUser,
      reloadSubscription, fetchSubscription,
      updateCredential, hasPrivilege,
      refreshToken,
      signOut
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export const useAuthContext = () => {
  return useContext(AuthContext) as AuthContextInterface;
}
