import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, useTheme } from '@mui/material';

interface Props {
  onClose: () => void;
  onAuthenticate: () => void;
  initialState: 'login' | 'register' | 'resetPassword';
}

const LoginPanel = ({ onClose, onAuthenticate, initialState }: Props) => {
  const [state, setState] = useState<'login' | 'register' | 'resetPassword'>(initialState);

  const theme = useTheme();

  const getRenderedComponent = () => {
    switch (state) {
      case 'login':
        return (
          <Login
            onAuthenticated={onAuthenticate}
            onNavigateRegister={() => setState('register')}
            onNavigateReset={() => setState('resetPassword')}
          />
        );

      case 'register':
        return (
          <Register
            onAuthenticated={onAuthenticate}
            onNavigateLogin={() => setState('login')} />
        );
      case 'resetPassword':
        return (
          <ResetPassword
            onAuthenticated={() => setState('login')}
            onNavigateLogin={() => setState('login')} />
        );
    }
  }
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        position: 'fixed',
        top: '0', bottom: '0', left: '0', right: '0',
        overflowY: 'auto'
      }}
      open={true}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '80%', sm: '450px' },
          backgroundColor: theme.palette.modal.main,
          borderRadius: '20px',
          margin: 'auto',
          position: 'relative',
          pt: '25px'
        }}>
        <IconButton
          sx={{ color: 'gray', position: 'absolute', right: '5px', top: '5px' }}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {getRenderedComponent()}
      </Box>
    </Backdrop>
  );
}

export default LoginPanel;
