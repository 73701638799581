import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  //*
  useEffect(() => {
    //Arbitrary delay to let browser address bar animation finish.
  //  setTimeout(() => {
     window.scrollTo(0, 0);    
//    }, 100);
  }, [pathname]);
  //*/

  return null;
}