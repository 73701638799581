import { useEffect } from 'react'
import axios from 'axios'

import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import useNavigateBack from '../Hooks/useNavigateBack';
import useNavigateRedirect from '../Hooks/useNavigateRedirect';
import Session from '../interfaces/Session';

const ScriptRouter = () => {
  const { scriptId } = useParams();

//  const location = useLocation();

  const redirect = useNavigateRedirect();
  const navigateBack = useNavigateBack();

  useEffect(() => {
    axios
      .get<Session>(`/api/scripts/${scriptId}/session`)
      .then(res => {
        console.log(res.data, 'axios')

        const sessionId = res.data.id;

        if (sessionId) redirect(`/session/${sessionId}`);
        else navigateBack();
      })
      .catch(error => {
        navigateBack();
      })
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
    </Box>
  );
}

export default ScriptRouter;
